/* CSS untuk hover seluruh minggu */
.week-picker .react-datepicker__week:hover,
.week-picker .react-datepicker__week-number:hover {
  background-color: #f2f0f0 !important;
  color: black !important;
}

/* Menyoroti seluruh minggu saat hover pada tanggal */
.week-picker .react-datepicker__day:hover,
.week-picker .react-datepicker__day.react-datepicker__day--highlighted {
  background-color: transparent !important;
  color: black !important;
}

/* Meng-highlight nomor minggu ketika hover pada tanggal di minggu yang sama */
.week-picker .react-datepicker__week:hover .react-datepicker__week-number,
.week-picker .react-datepicker__week-number:hover {
  background-color: green !important;
  border-radius: 3.5px;
  color: white !important;
  -webkit-border-radius: 3.5px;
  -moz-border-radius: 3.5px;
  -ms-border-radius: 3.5px;
  -o-border-radius: 3.5px;
}

/* Highlight seluruh minggu (termasuk nomor minggu dan tanggal) */
.week-picker .react-datepicker__day:hover ~ .react-datepicker__day,
.week-picker .react-datepicker__week-number:hover ~ .react-datepicker__day {
  background-color: transparent !important;
  color: black !important;
}

/* CSS untuk tanggal yang di-hover */
.week-picker .react-datepicker__day--highlighted {
  background-color: purple !important;
  color: black !important;
}

/* Styling untuk seluruh minggu yang terhighlight */
.week-picker .react-datepicker__week-number:hover,
.week-picker .react-datepicker__day--highlighted {
  background-color: gray !important;
  color: black !important;
}

.react-datepicker__day {
  cursor: pointer;
}

.react-datepicker__week-number {
  cursor: pointer;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container .form-control {
   flex: 1;
}